@font-face {
    font-family: Calibri;
    src: url(/app/static/media/Calibri.b62e3092.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Calibri;
    src: url("/app/static/media/Calibri Bold Italic.14ca17a7.ttf");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: Calibri;
    src: url("/app/static/media/Calibri Bold.d08805e7.ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: GT-Walsheim;
    src: url(/app/static/media/GT-Walsheim-Ultra-Bold.de363511.otf);
    font-weight: bold;
    font-style: normal;
}

html {
    display: flex;
    background: url(/app/static/media/Sports.a9a771a6.png);
}
#root {
    will-change: filter;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
            appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

* {
    box-sizing: border-box;
}

/* styles */
html {
    display: flex;
    background: url(/app/static/media/Sports.a9a771a6.png);
}
#root {
    will-change: filter;
}
/* responsive designs */
/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    body {
        max-height: 100%;
        max-width: 100%;
    }
    html {
        min-height: 480px;
    }

    /* for small devices */
    @media (max-width: 320px) {
        html {
            font-size: 6px;
        }
    }
}

@media only screen and (min-width: 600px) {
    body {
        max-height: 812px;
        max-width: 480px;
        padding: 0;
        box-shadow: 12px 24px 100px rgba(0, 0, 0, 0.5);
        border-radius: 2rem;
    }
    html {
        font-size: 7.5px;
        min-height: 580px;
    }
    #root {
        border-radius: 2rem;
    }
}

